import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ModalVideo from 'react-modal-video'

// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Elements
import Count from '../../elements/counter/counter-sensor';
import MainSlider from '../../elements/main-slider/slider1';
import About from '../../elements/about/about1';
import Team from '../../elements/team/team2';
import Testimonial from '../../elements/testimonial/testimonial1';
import BlogSlider from '../../elements/blog/blog-slider';
import BrandSlider from '../../elements/projects/brand-slider';
import ContactSidebar from '../../elements/contact-sidebar';

// Images
import blogGridPic1 from '../../../images/blog/grid/pic1.jpg';
import aboutPic2 from '../../../images/about/pic2.jpg';
import bgPic2 from '../../../images/background/bg8.png';
import bgPic3 from '../../../images/background/bg3.jpg';
import partnerLogo1 from '../../../images/partner/logo1.png';
import partnerLogo2 from '../../../images/partner/logo2.png';
import partnerLogo3 from '../../../images/partner/logo3.png';
import partnerLogo4 from '../../../images/partner/logo4.png';
import partnerLogo5 from '../../../images/partner/logo5.png';
import partnerLogo6 from '../../../images/partner/logo6.png';
import partnerLogo7 from '../../../images/partner/logo7.png';
import partnerLogo8 from '../../../images/partner/logo8.png';
import partnerLogo9 from '../../../images/partner/logo9.png';
import hand from '../../../images/hand.png';

class Index extends Component{
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
			
				
				<Header />
				
				<div className="page-content bg-white">
				
					<MainSlider />
					<ContactSidebar />
					
					<div className="content-block" id="content-area">
						
						<About />

						<div className="section-area section-sp3 ovpr-dark bg-fix about-media-bx parallax bgSayi">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 wow fadeIn" data-wow-delay="0.8s">
										<h2 className="m-b20">Sayılarla <b className='altunoz'>ALTUNÖZ</b></h2>

										<div className="row">
											
											<div className="col-lg-3 col-md-6 col-sm-6 m-b30 counter-items">
												<div className="counter-box">
												<div className="counterup-iconbox"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" className="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M880 112c-3.8 0-7.7.7-11.6 2.3L292 345.9H128c-8.8 0-16 7.4-16 16.6v299c0 9.2 7.2 16.6 16 16.6h101.7c-3.7 11.6-5.7 23.9-5.7 36.4 0 65.9 53.8 119.5 120 119.5 55.4 0 102.1-37.6 115.9-88.4l408.6 164.2c3.9 1.5 7.8 2.3 11.6 2.3 16.9 0 32-14.2 32-33.2V145.2C912 126.2 897 112 880 112zM344 762.3c-26.5 0-48-21.4-48-47.8 0-11.2 3.9-21.9 11-30.4l84.9 34.1c-2 24.6-22.7 44.1-47.9 44.1zm496 58.4L318.8 611.3l-12.9-5.2H184V417.9h121.9l12.9-5.2L840 203.3v617.4z"></path></svg></div>
													<div className="counter-num"><Count counter={1000}/>+</div>
													<div className="counter-text">Tamamlanan Projeler</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6 col-sm-6 m-b30 counter-items">
												<div className="counter-box">
												<div className="counterup-iconbox"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" className="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M434.7 64h-85.9c-8 0-15.7 3-21.6 8.4l-98.3 90c-.1.1-.2.3-.3.4-16.6 15.6-16.3 40.5-2.1 56 12.7 13.9 39.4 17.6 56.1 2.7.1-.1.3-.1.4-.2l79.9-73.2c6.5-5.9 16.7-5.5 22.6 1 6 6.5 5.5 16.6-1 22.6l-26.1 23.9L504 313.8c2.9 2.4 5.5 5 7.9 7.7V128l-54.6-54.6c-5.9-6-14.1-9.4-22.6-9.4zM544 128.2v223.9c0 17.7 14.3 32 32 32h64V128.2h-96zm48 223.9c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zM0 384h64c17.7 0 32-14.3 32-32V128.2H0V384zm48-63.9c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16c0-8.9 7.2-16 16-16zm435.9 18.6L334.6 217.5l-30 27.5c-29.7 27.1-75.2 24.5-101.7-4.4-26.9-29.4-24.8-74.9 4.4-101.7L289.1 64h-83.8c-8.5 0-16.6 3.4-22.6 9.4L128 128v223.9h18.3l90.5 81.9c27.4 22.3 67.7 18.1 90-9.3l.2-.2 17.9 15.5c15.9 13 39.4 10.5 52.3-5.4l31.4-38.6 5.4 4.4c13.7 11.1 33.9 9.1 45-4.7l9.5-11.7c11.2-13.8 9.1-33.9-4.6-45.1z"></path></svg></div>
													<div className="counter-num"><Count counter={100}/>+</div>
													<div className="counter-text">Memnun Müşteri</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6 col-sm-6 m-b30 counter-items">
												<div className="counter-box">
												<div className="counterup-iconbox"><img src={hand} /></div>
													<div className="counter-num"><Count counter={500}/>M+</div>
													<div className="counter-text">Yönetilen Bütçe</div>
												</div>
											</div>

											<div className="col-lg-3 col-md-6 col-sm-6 m-b30 counter-items">
												<div className="counter-box">
												<div className="counterup-iconbox"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" className="icon" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0 0 51.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg></div>
													<div className="counter-num"><Count counter={25}/>+</div>
													<div className="counter-text">Yıllık Tecrübe</div>
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
						</div>
						
						<div className="section-area our-partner mb-5" data-name="PARTNER">
							<div className="container">
								<div className="row">
									<div className="col-lg-12 section-sp3">
										<div className="heading-bx text-center">
											<h2 className="title-head m-b0">Referanslarımız</h2>
											<p className="m-b0">Bizimle çalışmayı tercih eden paydaşlar.</p>
										</div>
									</div>
									<div className="col-lg-12">
										<BrandSlider />
									</div>
								</div>
						
					
							</div>
						</div>
						

					</div>
				</div>

				<Footer/>
				
				<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='xneM6b83KCA' onClose={() => this.setState({isOpen: false})} />
				
			</>
		);
	}
}

export default Index;