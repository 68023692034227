import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'
import ModalVideo from 'react-modal-video'

// Layout
import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";

// Elements
import Team from '../../elements/team/team2';
import Testimonial from "../../elements/testimonial/testimonial2";
import ClientLogo from "../../elements/client-logo/logo-carousel";
import Counter from "../../elements/counter/counter2";

// Images
import bannerPic2 from '../../../images/banner/banner2.jpg';
import bgPic1 from '../../../images/background/bg1.jpg';
import bgPic2 from '../../../images/background/bg2.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import aboutPic1 from '../../../images/about/pic1.jpg';
import aboutPic2 from '../../../images/about/pic2.jpg';
import test from '../../../images/test/3.jpg';

class About2 extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
				<Header/>
				
				<div className="page-content bg-white">
					<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<span className="banner-sm-title">Altunöz</span>
								<h1 className="text-white">HAKKIMIZDA</h1>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-6 col-md-12 wow fadeIn" data-wow-delay="0.3s">
										<div className="heading-bx">
											<h2 className="title-head m-b0"><b className="altunoz">Altunöz</b> Hakkında?</h2>
											<p className="m-b0">1997 'deki yolculuğun başlangıcında, vizyonumuz bir ithalat ve ihracat şirketi olmaktı ve şimdi Avrupa'dan Orta Doğu'ya, Asya'dan Afrika'ya kadar uzanan projelerde uçtan uca tedarik zinciri çözümleri sunmaktayız. Birleşmiş Milletler Kuruluşlarının Türkiye'deki en büyük tedarikçilerinden biri olarak, amaca uygun, yenilikçi, özelleştirilebilir ürünler sağlayan optimal ürün ve hizmetlerimiz ile BM kuruluşları, özel şirketler ve büyük kuruluşlarla gururla çalışmaktayız. Altunöz olarak iş geliştirme, yenilenebilir enerji kaynakları, züccaciye sektörlerinin yanı sıra, hijyen setleri, kıyafet setleri, mutfak setleri, sünger yataklar, katlanır sandalyeler, battaniyeler, çeşitli temel yardım ve inşaat malzemeleri, halı, çadır, branda, bidon ve solar ürünler gibi gıda dışı ürünler sağlayan insani yardım alanlarında da faaliyet göstermekteyiz.</p>
											<Link to={"contact"} className="btn button-md radius-xl">İLETİŞİME GEÇ</Link>
										</div>
									</div>
									<div className="col-lg-6 col-md-12 wow fadeIn" data-wow-delay="0.6s">
										<div className="about-img-box">
											<img src={test} alt=""/>
											<img  alt=""/>
										</div>
									</div>
								</div>
							</div>
						</div>
						
						
						<div className="section-area bg-gray section-sp1">
							<div className="container">
								<Counter />
							</div>
						</div>
						
					
					</div>
				</div>
				
				<Footer/>
				
				
			</>
		);
	}
}

export default About2;