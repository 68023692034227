import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Link as ScrollTo} from 'react-scroll'

import Header from "../../layout/header/header1";
import Footer from "../../layout/footer/footer1";
import Counter from "../../elements/counter/counter2";
import bannerPic2 from '../../../images/banner/banner2.jpg';
import pattern1 from '../../../images/pattern/pt1.png';
import test from '../../../images/test/3.jpg';

class HumanPolicy extends Component {
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return (
			<>
				<Header/>
				
				<div className="page-content bg-white">
					<div className="page-banner ovbl-dark page-banner-lg ovdt1 overlay-dotted no-pt parallax" style={{backgroundImage: "url("+bannerPic2+")"}}>
						<div className="container">
							<div className="page-banner-entry">
								<span className="banner-sm-title">Altunöz</span>
								<h1 className="text-white">İNSAN KAYNAKLARI</h1>
							 </div>
						</div>
						<ScrollTo smooth={true} to="content-area" className="banner-bottom scroll-page"><i className="ti-arrow-down"></i></ScrollTo>
					</div>
					<div className="content-block" id="content-area">
						
						<div className="section-area section-sp2 bg-white text-center" style={{backgroundImage: "url("+pattern1+")"}}>
							<div className="container">
								<div className="row align-items-center">
									<div className="col-lg-12 col-md-12 wow fadeIn" data-wow-delay="0.3s">
										<div className="heading-bx">
											<h2 className="title-head m-b0">İNSAN KAYNAKLARI POLİTİKAMIZ</h2>
											<p className="m-b0">Çalışanlarımıza değer verir, onların mutluluğunu önemseriz</p>
											<p className="m-b0">Evrensel değerlerle hareket ederiz</p>
											<p className="m-b0">Çalışanlarımızın evrensel insani değerler çerçevesinde dil, din, ırk, yaş, cinsiyet, mezhep, düşünce özgürlüğü ayrımı yapılmaksızın kişiliğine saygı duyar, haklarını koruruz.</p>
											<p className="m-b0">İşe alım süreçlerimizde bbjektif davranırız</p>
											<p className="m-b0">İşin özelliği ve pozisyonun yetkinliklerine uygun çalışanın seçimini ve görevlendirilmesini sağlarız.</p>
											<p className="m-b0">Sürekli gelişimi hedefleriz</p>
											<p className="m-b0">Çalışanlarımızı gelişmelerden haberdar ederiz</p>
											<p className="m-b0">Çalışanlarımızı, özlük hakları ve çalışma koşulları konularında bilinçlendirir ve gelişmelerden zamanında haberdar ederiz.</p>
											<p className="m-b0">Takım çalışmasını prensip ediniriz</p>
											<p className="m-b0">Çalışma arkadaşlarımızı rakip değil, takımımızın bir parçası olarak görürüz. Büyük başarıların ancak ve ancak iyi ekip çalışmasıyla gerçekleşeceğini bilir, bu doğrultuda hareket ederiz.</p>
											<p className="m-b0">Tüm çalışanlarımızın inisiyatif almasını destekler ve işini sahiplenmesini sağlarız.</p>
											<p className="m-b0">Toplumsal fayda sağlarız.</p>
											<p className="m-b0">İnsan, hayvan ve çevre sağlığına uygun hareket eder, tüm çalışanlarımızı bu konularda bilinçlendiririz.</p>
											<p className="m-b0">Sosyal sorumluluk projelerimizle farklılık ve farkındalık yaratırız.</p>
										</div>
									</div>
									
								</div>
							</div>
						</div>
						
						
						<div className="section-area bg-gray section-sp1">
							<div className="container">
								<Counter />
							</div>
						</div>
						
					
					</div>
				</div>
				
				<Footer/>
				
				
			</>
		);
	}
}

export default HumanPolicy;