import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Images
import IconImg1 from "../../images/icon/contact/icon1.png"
import IconImg2 from "../../images/icon/contact/icon2.png"
import IconImg3 from "../../images/icon/contact/icon3.png"
import Logo from "../../images/logo-dark.png"

class ContactSidebar extends Component{
	
	componentDidMount() {
		
		// Contact Sidebar
        var asideBtn = document.querySelector(".slideinfo")
        var contactSidebar = document.querySelector(".contact-sidebar")
        var closeBtn = document.getElementById("clase-btn")

        asideBtn.addEventListener('click',function(){
            contactSidebar.classList.add("open")
        })

        closeBtn.addEventListener('click',function(){
            contactSidebar.classList.remove("open")
        })        
    }
	
	render(){
		return(
			<>
				<div className="slideinfo">
					<ul className="slide-social">
						<li>Sosyal Medya</li>
						<li className="sc-link"><Link to={"#"}><i className="fa fa-facebook"></i></Link></li>
						<li className="sc-link"><Link to={"#"}><i className="fa fa-twitter"></i></Link></li>
						<li className="sc-link"><Link to={"#"}><i className="fa fa-linkedin"></i></Link></li>
					</ul>
					<Link to={"contact"} className="slider-contact btn contact-sidebtn">İLETİŞİME Geç</Link>
				</div>
				<div className="contact-sidebar content-scroll">
					<div className="inner">
						<div className="logo">
							<img src={Logo} alt=""/>
						</div>
						<h4 className="title">Bizimle İletişime Geçin</h4>
						<ul className="contact-infolist">
							<li>
								<img src={IconImg1} alt=""/>
								<h6 className="contact-title">Telefon No</h6>
								<p>(0342) 231 17 20</p>
							</li>
							<li>
								<img src={IconImg2} alt=""/>
								<h6 className="contact-title">Mail Adresi</h6>
								<Link to="mailto:info@altunoz.com">info@altunoz.com</Link><br/>
							</li>
							<li>
								<img src={IconImg3} alt=""/>
								<h6 className="contact-title">Adres</h6>
								<p>Altunkaynak Plaza, Aydınlar Mah. 03063, Nolu Cd No 25/1, 27010 Şehitkamil</p>
							</li>
						</ul>
						
					</div>
				</div>
				<div className="contact-sidebtn close" id="clase-btn">
					<i className="ti-close"></i>
				</div>
			</>
		);
	}
}

export default ContactSidebar;