import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ModalVideo from 'react-modal-video'

// Images
import pattern1 from '../../../images/pattern/pt1.png';
import aboutPic1 from '../../../images/about/pic1.jpg';
import Count from '../../elements/counter/counter-sensor';
import test from '../../../images/test/3.jpg';

class About1 extends Component{
	
	constructor () {
		super()
		this.state = {
			isOpen: false
		}
		this.openModal = this.openModal.bind(this)
	}
	openModal () {
		this.setState({isOpen: true})
	}
	
	render(){
		return(
			<>
				<div className="section-area section-sp2 parallax" style={{backgroundImage: "url("+pattern1+")"}}>
					<div className="container">
						<div className="row">
							<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
								<div className="heading-bx">
									<h2 className="title-head">Firmamız Hakkında</h2>
									<p>1997 'deki yolculuğun başlangıcında, vizyonumuz bir ithalat ve ihracat şirketi olmaktı ve şimdi Avrupa'dan Orta Doğu'ya, Asya'dan Afrika'ya kadar uzanan projelerde uçtan uca tedarik zinciri çözümleri sunmaktayız. Birleşmiş Milletler Kuruluşlarının Türkiye'deki en büyük tedarikçilerinden biri olarak, amaca uygun, yenilikçi, özelleştirilebilir ürünler sağlayan optimal ürün ve hizmetlerimiz ile BM kuruluşları, özel şirketler ve büyük kuruluşlarla gururla çalışmaktayız. Altunöz olarak iş geliştirme, yenilenebilir enerji kaynakları, züccaciye sektörlerinin yanı sıra, hijyen setleri, kıyafet setleri, mutfak setleri, sünger yataklar, katlanır sandalyeler, battaniyeler, çeşitli temel yardım ve inşaat malzemeleri, halı, çadır, branda, bidon ve solar ürünler gibi gıda dışı ürünler sağlayan insani yardım alanlarında da faaliyet göstermekteyiz.</p>
								</div>
								
								<Link to={"about"} className="btn button-md radius-xl">Devamını Oku</Link>
							</div>
							<div className="col-lg-6 wow fadeInUp" data-wow-delay="0.4s">
								<div className="about-imgbox">
									<img src={test} alt=""/>
									<div className="about-year">
										<h2><Count counter={25}/>+</h2>
										<h5>Yıllık Serüven</h5>
										<Link to={"#"} onClick={this.openModal} className="popup-youtube videoplay-bx"><i className="fa fa-play"></i> <span>VİDEOYU Oynat</span></Link>
										{/*<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='xneM6b83KCA' onClose={() => this.setState({isOpen: false})} />*/}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default About1;