import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick"; 

// Images
import brand1 from "../../../images/referans/1.jpg"
import brand2 from "../../../images/referans/2.jpg"
import brand3 from "../../../images/referans/3.jpg"
import brand4 from "../../../images/referans/4.jpg"
import brand5 from "../../../images/referans/5.jpg"
import brand6 from "../../../images/referans/6.jpg"
import brand7 from "../../../images/referans/7.jpg"
import brand8 from "../../../images/referans/8.jpg"
import brand9 from "../../../images/referans/9.jpg"
import brand10 from "../../../images/referans/10.jpg"
import brand11 from "../../../images/referans/11.jpg"
import brand12 from "../../../images/referans/12.jpg"
import brand13 from "../../../images/referans/13.jpg"
import brand14 from "../../../images/referans/14.jpg"
import brand15 from "../../../images/referans/15.jpg"
import brand16 from "../../../images/referans/16.jpg"
import brand17 from "../../../images/referans/17.jpg"
import brand18 from "../../../images/referans/18.jpg"
import brand19 from "../../../images/referans/19.jpg"
import brand20 from "../../../images/referans/20.jpg"
import brand21 from "../../../images/referans/21.jpg"
import brand22 from "../../../images/referans/22.jpg"
import brand23 from "../../../images/referans/23.jpg"
import brand24 from "../../../images/referans/24.jpg"
import brand25 from "../../../images/referans/25.jpg"
import brand26 from "../../../images/referans/26.jpg"


const content = [
	{thumb: brand1},
	{thumb: brand2},
	{thumb: brand3},
	{thumb: brand4},
	{thumb: brand5},
	{thumb: brand6},
	{thumb: brand7},
	{thumb: brand8},
	{thumb: brand9},
	{thumb: brand10},
	{thumb: brand11},
	{thumb: brand12},
	{thumb: brand13},
	{thumb: brand14},
	{thumb: brand15},
	{thumb: brand16},
	{thumb: brand17},
	{thumb: brand18},
	{thumb: brand19},
	{thumb: brand20},
	{thumb: brand21},
	{thumb: brand22},
	{thumb: brand23},
	{thumb: brand24},
	{thumb: brand25},
	{thumb: brand26},
]

class BrandSlider extends Component{
	render(){
		
		const settings = {
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
					}
				},
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
		
		return(
			<>
				<Slider {...settings} className="recent-brand-carousel owl-btn-1 owl-btn-primary">
					{content.map((item, id) => (
						<div className="slider-item">
							<img src={item.thumb} alt=""/>
						</div>
					))}
				</Slider>
			</>
		);
	}
}

export default BrandSlider;