import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";

// Images
import sliderImg1 from "../../../images/slider/newslider1.jpg"
import sliderImg2 from "../../../images/slider/slide2.jpg"
import test from "../../../images/test/1.jpg"

class Slider1 extends Component{
	
	render(){
		
		const settings = {
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};
		
		return(
			<>
				
				<Slider {...settings} className="tt-slider slider-one owl-btn-1 slider-sp0">
					<div className="slider-item">
						<div className="slider-thumb ovbl-middle">
							<img src={sliderImg1} alt=""/>
						</div>
						<div className="slider-content text-white">
							<div className="container-fluid">
								<div className="content-inner">
									<h2 className="title" style={{textTransform: "capitalize"}}><b className='altunoz'>W</b>e are ready <br /> <b className='altunoz'>f</b>or unexpected</h2>
									<Link className="btn button-md radius-xl" to={"#"}>PROJELERİMİZ</Link>
								</div>
							</div>
						</div>
					</div>			
				</Slider>
			</>
		);
	}
}

export default Slider1;
